import Homepage from "./home";

export default function Home() {
  return (
    <>
        
      <Homepage />
    </>
  );
}
